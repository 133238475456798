import { useSearchParams } from "react-router-dom";
import {
  AuthParams,
  ConfigParams,
  ExtraParams,
  QueryParams,
} from "@src/types/queryParams";
import { useCallback, useMemo } from "react";
import type { Keys } from "@introvoke/react/types/utils";

/**
 * helper function to get a params but return undefined instead of null
 * @returns string | undefined
 */
const getParam = (params: URLSearchParams, key: string): string | undefined => {
  const value = params.get(key);
  return value === null ? undefined : value;
};

/**
 * returns all expected query params used within the entire application
 * @returns QueryParams
 */
export const useQueryParams = (): QueryParams => {
  const [params] = useSearchParams();

  return {
    test: getParam(params, "test"),
    cleanUI: getParam(params, "cleanUI"),
    hybridMode: getParam(params, "hybridMode"),
    filter: getParam(params, "filter"),
    nameOnlyEntry: getParam(params, "nameOnlyEntry"),
    sort: getParam(params, "sort"),
    returnToEventId: getParam(params, "returnToEventId"),
    meeting: getParam(params, "meeting"),
    mode: getParam(params, "mode"),
    joinCode: getParam(params, "joinCode"),
    authKey: getParam(params, "authKey"),
    userName: getParam(params, "userName"),
    userProfile: getParam(params, "userProfile"),
    userId: getParam(params, "userId"),
    userEmail: getParam(params, "userEmail"),
  };
};

/**
 * returns query params not related to config or authentication methods
 * @returns AuthParams
 */
export const useExtraParams = (): ExtraParams => {
  const { returnToEventId } = useQueryParams();
  return useMemo<ExtraParams>(() => ({ returnToEventId }), [returnToEventId]);
};

/**
 * returns query params related to authentication methods
 * @returns AuthParams
 */
export const useAuthParams = (): AuthParams => {
  const { joinCode, authKey, userName, userProfile, userId, userEmail } =
    useQueryParams();
  return useMemo<AuthParams>(
    () => ({ joinCode, authKey, userName, userProfile, userId, userEmail }),
    [joinCode, authKey, userName, userProfile, userId, userEmail],
  );
};

/**
 * returns query params related to config
 * @returns ConfigParams
 */
export const useConfigParams = (): ConfigParams => {
  const {
    test,
    cleanUI,
    nameOnlyEntry,
    hybridMode,
    filter,
    sort,
    meeting,
    mode,
  } = useQueryParams();
  return useMemo<ConfigParams>(
    () => ({
      test,
      cleanUI,
      nameOnlyEntry,
      hybridMode,
      filter,
      sort,
      meeting,
      mode,
    }),
    [test, cleanUI, nameOnlyEntry, hybridMode, filter, sort, meeting, mode],
  );
};

/**
 * returns a function to set query params
 *
 * If the value is `null | ""`, the query param is removed, otherwise the query param is updated
 */
export const useSetQueryParam = () => {
  const [params, setParams] = useSearchParams();

  return useCallback(
    (key: Keys<QueryParams>, value: string | null) => {
      if (!value) {
        params.delete(key);
      } else {
        params.set(key, value);
      }

      setParams(params);
    },
    [params, setParams],
  );
};
